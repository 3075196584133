import FrontPage from "./front_page.js";
import React , { useState, useRef, useEffect} from 'react';
import { useLocation} from "react-router-dom";
import NewNavBar from "../navbar/new_navbar.js";
import {FormattedMessage,useIntl} from 'react-intl';
import {Form} from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {config} from '../../Constants';
import Card from 'react-bootstrap/Card';
import * as Functions from '../../functions/functions';
import axios from "../../context/axios_instance";
import Select from 'react-select';
import { Rings } from 'react-loader-spinner';
import Calendar from '../calendar/doctor_calendar';
import { FaAngleRight, FaAngleLeft, FaCrosshairs, FaHome, FaRedo,FaExclamationCircle } from "react-icons/fa";
import LoadingOverlay from 'react-loading-overlay-ts';
import Roles from '../../roles';
import Footer from "../footer/footer.js";
import Loading from "../loading/loading";
import TargetText from "../targettext/target_text.js";
const searchModel= {
  specialityIds: [],
  diseaseIds: [],
  symptomIds: [],
  bodyPartIds: [],
  orderBy:'',
  professionGroupId:"00000000-0000-0000-0000-000000000000",
  location:{
    city:"",
    countryName:"",
    regionName:"",
    currentAddress:""
  }
};
function SearchDashboard() {
  const roles = localStorage.getItem('roles');
  const intl = useIntl();
  const initialSearchTypeOptions = [{
    value:0,
    label:intl.formatMessage({id: 'NewBooking'})
  },
  {
    value:1,
    label:intl.formatMessage({id: 'Statements'})
  },
  {
    value:2,
    label:intl.formatMessage({id: 'HouseCall'})
  },
  ]
  const [searchTypeOptions, setSearchTypeOptions] = useState(initialSearchTypeOptions);
  const location = useLocation();
  const [expandedItems, setExpandedItems] = useState({});
  const [professionGroupMenu, setProfessionGroupMenu] = useState(null);
  const [searchTypeMenu, setSearchTypeMenu] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [selectedSearchTypeOption, setSelectedSearchTypeOption] = useState([]);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [cAddress, setCAddress] = useState(null);
  const [currAddress, setCurrentAddress] = useState(null);
  const [doctorPrice, setDoctorPrice] = useState(null);
  const [doctorDrivingDuration, setDoctorDrivingDuration] = useState(0);
  const [doctorDrivingDistance, setDoctorDrivingDistance] = useState(null);
  const [active, setActive] = useState(false);
  const [oneProfession, setOneProfession] = useState(false);
  const [searchCall, setSearchCall] = useState(false);
  const [noStatementsAvailable, setNoStatementsAvailable] = useState(false);
  const [diseases, setDiseases] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [searchedServices, setSearchedServices] = useState([]);
  const [searchedDoctors, setSearchedDoctors] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [professionList, setProfessionList] = useState([]);
  const [statement, setStatement] = useState(false);
  const [houseCall, setHouseCall] = useState(false);
  const [professionGroup, setProfessionGroup] = useState(false);
  const [professionGroupIsSelected, setProfessionGroupIsSelected] = useState(false);
  const [statementError, setStatementError] = useState(false);
  const [bookingTitle, setBookingTitle] = useState("NewBooking");
  const [searchBodyText, setSearchBodyText] = useState("SpecialityBodytext");
  const [statements, setStatements] = useState([]);
  const [statementsList, setStatementsList] = useState([]);
  const [selectedStatement, setSelectedStatement] = useState([]);
  const [statementDoctors, setStatementDoctors] = useState([]);
  var arrayMonth = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
  const isLoggedIn = localStorage.getItem('access_token');
  let isMobile=false;
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
       || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
          isMobile=true;
   }
  const searchSpeciality = (e) => {
    if(searchModel.specialityIds.length <= e.length && !statement && !houseCall && !professionGroup){
      goToCard('CardSymptoms');  /* Skip bodypart */
    }
    searchModel.specialityIds=[];
    for(var i=0;i<e.length;i++){
      searchModel.specialityIds.push(e[i].value);
    }
    setSelectedSpeciality(e);
    if(statement){
      GetStatementsForSpeciality();
    }
    else if(houseCall){

    }
    else{
      GetSearchedItemDataLinq(false);
    }
  }
  function LoadingSearch(){
    document.getElementById("WarningDoctorDiv").style.display="none";
    if((searchModel.diseaseIds[0] !=='00000000-0000-0000-0000-000000000000' && searchModel.diseaseIds.length !== 0) || (searchModel.diseaseIds[0] ==='00000000-0000-0000-0000-000000000000' && searchModel.symptomIds.length !== 0)){
      document.getElementById("DoctorDiv").style.display="none";
      document.getElementById("LoadingDiv").style.display="flex";
      GetSearchedItemDataLinq(true);
     }else if(searchModel.diseaseIds[0] ==='00000000-0000-0000-0000-000000000000' && searchModel.symptomIds.length === 0){
      document.getElementById("WarningDoctorDiv").style.display="flex";
     }else{
      GetSearchedItemDataLinq(false);
      document.getElementById("LoadingDiv").style.display="flex";
      document.getElementById("HeightDiv").style.display="none";
      document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'Loading'});
     }
  }

  const searchDisease = (e) => {
    if(e.value===""){
      searchModel.diseaseIdsCompound=[];
      searchModel.diseaseIdsCompound.push("00000000-0000-0000-0000-000000000000");
    }else{
      searchModel.diseaseIds=[];
      searchModel.diseaseIds.push(e.value);
    }
    setSelectedDiseases(e);
    LoadingSearch();
  }

  const searchSymptoms = (e) => {
    if(searchModel.symptomIds.length <= e.length){
      goToCard('CardDisease');
    }
    searchModel.symptomIds=[];
    for(var i=0;i<e.length;i++){
      searchModel.symptomIds.push(e[i].value);
    }
    setSelectedSymptoms(e);
    LoadingSearch();
  }

  function searchProfessionGroupMobile(id, professionListParam, startSearch){
    if(startSearch){
      if (isLoggedIn === null) { 
        document.getElementById("FrontPage").style.display="none";
      }else{
        document.getElementById("targetTextClass").style.display="none";
      }
    document.getElementById("breadcrumb").style.display = "";
    }
    setStatementDoctors([]);
    document.getElementById("professionGroupDiv").style.display="none";
    searchModel.diseaseIds = [];
    searchModel.professionGroupId = "00000000-0000-0000-0000-000000000000";
    let profession = professionList.find(item => item.professionGroupId === id);
    if(professionListParam){
      profession = professionListParam.find(item => item.professionGroupId === id);
    }
    if(profession){
      setProfessionGroupMenu(profession.name);
      if(profession.searchByProfessionGroup){
        setProfessionGroup(true);
        searchModel.professionGroupId = profession.professionGroupId;
        goToCard('CardSpeciality'); 
      }else{
        setProfessionGroup(false);
      }
      GetSearchedItemDataLinq();
      setProfessionGroupIsSelected(true);
      if(document.getElementById("searchTypeDiv")){
        document.getElementById("searchTypeDiv").style.display = "none";
      }
      document.getElementById("bookingTile").style.display = "none";
      setTimeout(function () {
        setActiveSearchTypesMobile(profession.searchTypes);
    }, 500);
    }
  }

  const searchProfessionGroupDropdown = (e) => {
    if (isLoggedIn === null) { 
      document.getElementById("FrontPage").style.display="none";
    }else{
      document.getElementById("targetTextClass").style.display="none";
    }
    if(document.getElementById("CardLocation")){
      document.getElementById("CardLocation").style.display="none";
    }
    setStatementDoctors([]);
    document.getElementById("breadcrumb").style.display = "";
    document.getElementById("professionGroupDiv").style.display="none";
    searchModel.diseaseIds = [];
    setSelectedProfessions(e);
    searchModel.professionGroupId = "00000000-0000-0000-0000-000000000000";
    let profession = professionList.find(item => item.professionGroupId === e.value);
    if(profession){
      setProfessionGroupMenu(profession.name);
      if(profession.searchByProfessionGroup){
        setProfessionGroup(true);
        searchModel.professionGroupId = profession.professionGroupId;
        goToCard('CardSpeciality'); 
      }else{
        setProfessionGroup(false);
      }
      GetSearchedItemDataLinq();
      setProfessionGroupIsSelected(true);
      if(document.getElementById("searchTypeDiv")){
        document.getElementById("searchTypeDiv").style.display = "none";
      }
      document.getElementById("bookingTile").style.display = "none";
      setTimeout(function () {
        setActiveSearchTypesMobile(profession.searchTypes);
    }, 500);
    }
  }

  function searchProfessionGroupButton(id, professionListParam = null, startSearch = true){
    if(startSearch){
      if (isLoggedIn === null) { 
        document.getElementById("FrontPage").style.display="none";
      }else{
        document.getElementById("targetTextClass").style.display="none";
      }
    document.getElementById("breadcrumb").style.display = "";
    }
    if(document.getElementById("CardLocation")){
      document.getElementById("CardLocation").style.display="none";
    }
    setStatementDoctors([]);
    document.getElementById("professionGroupDiv").style.display="none";
    searchModel.diseaseIds = [];
    if(document.getElementById("searchForBookings")){
      document.getElementById("searchForBookings").classList.add("unselected-search-type");
    }
    if(document.getElementById("searchForHouseCalls")){
      document.getElementById("searchForHouseCalls").classList.add("unselected-search-type");
    }
    if(document.getElementById("searchForStatements")){
      document.getElementById("searchForStatements").classList.add("unselected-search-type");
    }
    searchModel.professionGroupId = "00000000-0000-0000-0000-000000000000";
    let profession = professionList.find(item => item.professionGroupId === id);
    if(professionListParam){
      profession = professionListParam.find(item => item.professionGroupId === id);
    }
    if(profession){
      setProfessionGroupMenu(profession.name);
      if(profession.searchByProfessionGroup){
        setProfessionGroup(true);
        searchModel.professionGroupId = profession.professionGroupId;
        goToCard('CardSpeciality');
      }else{
        setProfessionGroup(false);
      }
      GetSearchedItemDataLinq();
      let professionGroupButtons = document.querySelectorAll(".profession-group-button");
      professionGroupButtons.forEach(button => {
          if (button.getAttribute("data-id") === id) {
              button.classList.remove("unselected-search-type");
          } else {
              button.classList.add("unselected-search-type");
          }
      });
      setProfessionGroupIsSelected(true);
      if(document.getElementById("searchTypeDiv")){
        document.getElementById("searchTypeDiv").style.display = "none";
      }
      document.getElementById("bookingTile").style.display = "none";
      setTimeout(function () {
        setActiveSearchTypesFullScreen(profession.searchTypes);
    }, 500);
    }
  }

function resetDoctors(){
    resetSearch();
    goToCard('CardSpeciality');
  }

 function OrderBy(item){
  searchModel.orderBy=item;
  GetSearchedItemDataLinq(false);
 }

function GetSpecialityList(){
  document.getElementById("HeightDiv").style.display="none";
  var lang = localStorage.getItem('lang');
  fetch(config.url.BASE_URL+`Home/GetSpecialityList/${lang}`,{
    method:'Get',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    }
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          setActive(false);
          setSpecialities(result.selectSpecialities);
          document.getElementById("WarningDoctorDiv").style.display="none";
          document.getElementById("DoctorDiv").style.display="none";
          document.getElementById("LoadingDiv").style.display="none";
          SetSearchedControlsEnable();
    }
    )
}

function GetProfessionList(){
  var lang = localStorage.getItem('lang');
  fetch(config.url.BASE_URL+`Home/GetProfessionList/${lang}`,{
    method:'Get',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    }
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          setProfessions(result.selectProfessions);
          setProfessionList(result.professionList);
          if(result.professionList.length === 1){
            setOneProfession(true);
            if(isMobile){
              searchProfessionGroupMobile(result.professionList[0].professionGroupId, result.professionList, false);
            }else{
              searchProfessionGroupButton(result.professionList[0].professionGroupId, result.professionList, false);
            }
          }else{
            document.getElementById("professionGroupDiv").style.display = "";
          }
    }
    )
}

function setActiveSearchTypesFullScreen(types){
  if(types && !types?.includes("1") && !types?.includes("2")){
    document.getElementById("searchTypeDiv").style.display = "none";
    document.getElementById("bookingTile").style.display = "none";
  }else{
    document.getElementById("searchTypeDiv").style.display = "";
  }
  if(!types?.includes("0") && types){
    document.getElementById("searchForBookings").style.display = "none";
  }else{
    document.getElementById("searchForBookings").style.display = "";
  }
  if(!types?.includes("1") && types){
    document.getElementById("searchForStatements").style.display = "none";
  }else{
    document.getElementById("searchForStatements").style.display = "";
  }
  if(!types?.includes("2") && types){
    document.getElementById("searchForHouseCalls").style.display = "none";
  }else{
    document.getElementById("searchForHouseCalls").style.display = "";
  }
}

function setActiveSearchTypesMobile(types){
  let updatedOptions = initialSearchTypeOptions.filter(option => 
    !types || types.includes(option.value.toString())
  );
  setSearchTypeOptions(updatedOptions);
  setSelectedSearchTypeOption([]);
  if(types && !types?.includes("1") && !types?.includes("2")){
    document.getElementById("searchTypeDiv").style.display = "none";
    document.getElementById("bookingTile").style.display = "none";
  }else{
    document.getElementById("searchTypeDiv").style.display = "";
  }
}

function GetSpecialityListStatements(){
  document.getElementById("HeightDiv").style.display="none";
  var lang = localStorage.getItem('lang');
  fetch(config.url.BASE_URL+`Home/GetSpecialityListStatements/${lang}`,{
    method:'Get',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    }
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          setActive(false);
          setSpecialities(result.selectSpecialities);
          document.getElementById("WarningDoctorDiv").style.display="none";
          document.getElementById("DoctorDiv").style.display="none";
          document.getElementById("LoadingDiv").style.display="none";
          SetSearchedControlsEnable();
    }
    )
}

function GetSearchedItemDataLinq(showLocation){
  SetSearchedControlsDisable();
  document.getElementById("HeightDiv").style.display="none";
  setCalendar([]);
  if(searchModel.diseaseIds.length !== 0){
    document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'CalculateDistance'});
    setTimeout(function () {
      document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'SearchDoctor'});
  }, 2000);
  }else if(searchModel.specialityIds.length !== 0){
    document.getElementById("LoadingDiv").style.display="flex";
    document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'Loading'});
  }
searchModel.location.currentAddress=document.getElementById('AddressId').value;
searchModel.maxDistance=document.getElementById("MaxDistance").value;
searchModel.houseCall = false;
searchModel.culture = localStorage.getItem('lang');
if(showLocation){
  document.getElementById("CardLocation").style.display="flex";
}
fetch(config.url.BASE_URL+'Home/GetSearchedItemDataLinq/',{
  method:'POST',
  headers:{
      'Accept':'application/json',
      'Content-Type':'application/json'
  },
  body:JSON.stringify(searchModel)
  })
  .then(res=>res.json())
  .then((result)=>{
        console.log(result);
        setActive(false);
        setSymptoms(result.selectSymptoms);
        setSpecialities(result.selectSpecialities);
        const options = { value: '00000000-0000-0000-0000-000000000000', label: intl.formatMessage({id: 'IDK'}) };
        var diseasesList=result.selectDiseases;
        diseasesList.unshift(options);
        setDiseases(diseasesList);
        if(result.searchedDoctors !== null){
          setSearchedDoctors(result.searchedDoctors);
        }
        setSearchedServices(result.searchedServices);
        if(result.searchedDoctors !== null && result.searchedDoctors.length !==0 && ((searchModel.diseaseIds[0] !=='00000000-0000-0000-0000-000000000000' && searchModel.diseaseIds.length !== 0) || (searchModel.diseaseIds[0] ==='00000000-0000-0000-0000-000000000000' && searchModel.symptomIds.length !== 0))){
          document.getElementById("DoctorDiv").style.display="flex";
          document.getElementById("CardLocation").style.display="flex";
          document.getElementById("WarningDoctorDiv").style.display="none";
        }else if(result.searchedDoctors === null || result.searchedDoctors.length ===0){
          document.getElementById("WarningDoctorDiv").style.display="none";
          document.getElementById("DoctorDiv").style.display="none";
        }
        document.getElementById("LoadingDiv").style.display="none";
        SetSearchedControlsEnable();
        if(result.selectSpecialities.length === 1 && searchModel.professionGroupId !== "00000000-0000-0000-0000-000000000000"){
          searchModel.specialityIds=[];
          searchModel.specialityIds.push(result.selectSpecialities[0].value);
           searchForProfessionGroups();
        }
  }
  )
}

  const AutoComplete = () => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
      //  componentRestrictions: { country: "dk" },
       types: ["geocode"]
      };

    function changeAddress(){
      setCAddress(document.getElementById('AddressId').value);
      document.getElementById("DoctorDiv").style.display="none";
      document.getElementById("WarningDoctorDiv").style.display="none";
      document.getElementById("LoadingDiv").style.display="flex";
      if(statement || houseCall || professionGroup){
        getDoctorsForStatement(selectedStatement.value);
      }else{
        GetSearchedItemDataLinq(false);
      }
    }

  useEffect(() => {
    setTimeout(() => {
      try {
        const wrapper = document.querySelector('[data-testid="wrapper"]');
        if (wrapper) {
          wrapper.style.height = "100vh";
        }

        const selectInputs = ['react-select-2-input', 'react-select-3-input', 'react-select-4-input', 'react-select-5-input', 'react-select-6-input'];
        selectInputs.forEach(id => {
          const element = document.getElementById(id);
          if (element) {
            element.classList.add('cursor-center');
          }
        });

        navigator.geolocation.getCurrentPosition(function(position) {
              const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
              };
            let currentAddress;
            const google = window.google;
            var geocoder = new google.maps.Geocoder();
            let google_map_position = new google.maps.LatLng(pos.lat, pos.lng);
            geocoder.geocode({ 'latLng': google_map_position }, function (results, status) {
                if (status === 'OK' && results && results[0]) {
                        currentAddress = results[0].formatted_address;
                        setCurrentAddress(currentAddress);
                        if(cAddress==null){
                          setCAddress(currentAddress);
                        }
                        searchModel.location.currentAddress=cAddress;
                        document.getElementById('AddressId').value=cAddress;
                  }
                });
          });

          if (inputRef.current) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
            searchModel.location.currentAddress = cAddress;
            const addressInput = document.getElementById('AddressId');
            if (addressInput) {
              addressInput.value = cAddress;
            }
          }

         if(!searchCall){
          GetSpecialityList();
          GetProfessionList();
          GetSearchedItemDataLinq();
          setSearchCall(true);
         }

        }catch (exceptionVar) {
          console.log(exceptionVar);
        }
      }, 500);
    }, []);
    return (
       <Form.Control  type="text" id='AddressId' autoComplete='off' ref={inputRef} onBlur={(e) => {changeAddress()}} className="row-Search"></Form.Control>
    );
  };

  function GetHomeLocation(){
    axios.get(`Patient/Home/GetHomeLocation`)
    .then(
      (result) => {
        let address = Functions.decryptString(result.data);
        document.getElementById("AddressId").value=address;
        searchModel.location.currentAddress=address;
        setCAddress(address);
        document.getElementById("DoctorDiv").style.display="none";
        document.getElementById("WarningDoctorDiv").style.display="none";
        document.getElementById("LoadingDiv").style.display="flex";
        if(statement || houseCall || professionGroup){
          getDoctorsForStatement(selectedStatement.value);
        }else{
          GetSearchedItemDataLinq(false);
        }
      }
    )
  }

  function GetCurrentLocation(){
    if(currAddress!==null){
      document.getElementById("DoctorDiv").style.display="none";
      document.getElementById("WarningDoctorDiv").style.display="none";
      document.getElementById("LoadingDiv").style.display="flex";
      document.getElementById("AddressId").value=currAddress;
      searchModel.location.currentAddress=currAddress;
      setCAddress(currAddress);
      if(statement || houseCall){
        getDoctorsForStatement(selectedStatement.value);
      }else{
        GetSearchedItemDataLinq(false);
      }
    }
  }

  const GetMaxDistance = (e) => {
    document.getElementById("DoctorDiv").style.display="none";
    document.getElementById("WarningDoctorDiv").style.display="none";
    document.getElementById("LoadingDiv").style.display="flex";
    GetSearchedItemDataLinq(false);
  }
 
  function goToCard(cardId){
    if(searchModel.specialityIds.length===0 && symptoms.length===0 && cardId==="CardSymptomsFirst"){
      document.getElementById("LoadingDiv").style.display="flex";
      document.getElementById("HeightDiv").style.display="none";
      document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'Loading'});
    }
    if(cardId==="CardSymptomsFirst"){
      cardId="CardSymptoms";
    }
    if(document.getElementById("CardSpeciality")){
      document.getElementById("CardSpeciality").style.display="none";
    }
    document.getElementById("CardSymptoms").style.display="none";
    document.getElementById("CardDisease").style.display="none";
    if(document.getElementById(cardId)){
      document.getElementById(cardId).style.display="block";
    }
  }

function getMonth(month){
    if(month<10){
        month = month.substring(1,2);
    }
    return arrayMonth[month];
}

function getDistance(item, index){
     return AccordionItem(item, index);
}

function GetCalendar(doctorId, price, duration, distance, weekstart) {
  setCalendar([]);
  document.getElementById("HeightDiv").style.display="block";
  document.getElementById("LoadingDiv2").style.display="flex";
  document.getElementById("LoadingDiv2").scrollIntoView();
  document.getElementById("LoadingDivCalendar").style.display="flex";
  var lang = localStorage.getItem('lang');
  if(!lang){
    lang="da";
  }
  fetch(config.url.BASE_URL+`Home/GetDoctor/${doctorId}/${lang}/${distance.toString()}/${weekstart === undefined ? "" : weekstart}`)
  .then(response => response.json())
  .then(data => {
        document.getElementById("LoadingDivCalendar").style.display="none";
        setCalendar(data);
        setDoctorPrice(price);
        setDoctorDrivingDuration(duration);
        setDoctorDrivingDistance(distance.toString());
      } 
      );
}

function getDoctorCalendar(){
  if(calendar.length !== 0){
    document.getElementById("LoadingDiv2").style.display="none";
    document.getElementById("HeightDiv").style.display="none";
    var differenceDuration=0;
    if(statement){
       var statementId = selectedStatement.value;
       var statementDuration = statementsList.find(statement => statement.serviceId === statementId)?.minimumDuration;
       differenceDuration = statementDuration - calendar.timeSlotDuration;
       searchModel.diseaseIds[0] = statementId;
       const filteredModels = [];
       for (let i = 0; i < calendar.timeSlots.length; i++) {
          let sum = 0;
          for (let j = i + 1; j < calendar.timeSlots.length; j++) {
              sum = timeDifference(calendar.timeSlots[i].start, calendar.timeSlots[j].start, j-i);
              if(sum >= differenceDuration * 60 * 1000){
                var exist = filteredModels.find(model => model.timeSlotId === calendar.timeSlots[i].timeSlotId);
                if(!exist){
                  filteredModels.push(calendar.timeSlots[i]);
                }
              }
          }
      }
     calendar.timeSlots = filteredModels;
    }else if(houseCall){
      var houseCallId = selectedStatement.value;
      var houseCallDuration = statementsList.find(statement => statement.serviceId === houseCallId)?.minimumDuration;
      differenceDuration = houseCallDuration - calendar.timeSlotDuration + doctorDrivingDuration;
      searchModel.diseaseIds[0] = houseCallId;
      const filteredModels = [];
       for (let i = 0; i < calendar.timeSlots.length; i++) {
          let sum = 0;
          for (let j = i + 1; j < calendar.timeSlots.length; j++) {
              sum = timeDifference(calendar.timeSlots[i].start, calendar.timeSlots[j].start, j-i);
              if(sum >= differenceDuration * 60 * 1000){
                var exist = filteredModels.find(model => model.timeSlotId === calendar.timeSlots[i].timeSlotId);
                if(!exist){
                  filteredModels.push(calendar.timeSlots[i]);
                }
              }
          }
      }
     calendar.timeSlots = filteredModels;
   }else if(professionGroup){
    var professionGroupServiceId = selectedStatement.value;
    searchModel.diseaseIds[0] = professionGroupServiceId;
   }
    return  <Calendar data={calendar} patientAddress={searchModel.location.currentAddress} doctorPrice={doctorPrice} doctorDrivingDistance={doctorDrivingDistance} doctorDrivingDuration={doctorDrivingDuration} differenceDuration={differenceDuration} statement={statement} professionGroup={professionGroup} houseCall={houseCall} diseaseId={searchModel.diseaseIds[0]} symptomIds={searchModel.symptomIds} address={cAddress} searchedServices={searchedServices}/>
  }
}

function timeDifference(start, end, dif) {
  const startTime = new Date(start);
  const endTime = new Date(end);
  const differenceInMs = endTime.getTime() - startTime.getTime();
  if(30 * dif * 60 * 1000 !== differenceInMs){
    return 0;
  }
  return differenceInMs;
}

  // Function to toggle the expanded state for a specific item
  const toggleText = (index) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

function AccordionItem(item, index){
  var distance = item.distance;
  if(item.distance?.toString().includes(".")){
    distance=item.distance.toString().substring(0,item.distance.toString().indexOf("."));
  }
    var firstTimeSlot = item.firstAvailableTime.substring(8, 10)+" "+getMonth(item.firstAvailableTime.substring(5,7));
    if(item.firstAvailableTime==="0001-01-01T00:00:00"){
      firstTimeSlot=<FormattedMessage id="No date"/>;
    }
    var warning = "";
    if(searchModel.diseaseIds[0]==='00000000-0000-0000-0000-000000000000' && searchModel.symptomIds.length === 0){
      warning=<div style={{padding:"12px"}}><FormattedMessage id="DoctorCalendarMessage"/><FaExclamationCircle style={{color:"#f1bd4b",marginLeft:"5px"}} /></div>;
    }
    const servicesText = item.services.map(service => service.name).join(", ");
    const isExpanded = expandedItems[index] || false;
    const truncatedText = servicesText.length > 200 ? servicesText.slice(0, 200) + "..." : servicesText;
      return <Card style={{color: "#007bff",marginBottom:"10px", width:"100%"}}>
      <Card.Header  as="h5" style={{background:"#e7f1ff"}}>{firstTimeSlot} - {item.name}</Card.Header>
      <Card.Body>
        <Card.Text>
        <b><FormattedMessage id="Speciality"/>:</b> {item.specialityName}<br></br>
        <div style={{display:"flex"}}><b><FormattedMessage id="Availability"/>:</b> {getProgress(item.availability)}<br></br></div>
        <b><FormattedMessage id="Distance"/>:</b> {distance} km<br></br>
        <b><FormattedMessage id="Services"/>:</b>
      {isExpanded ? servicesText : truncatedText}
      {servicesText.length > 200 && (
        <span onClick={() => toggleText(index)} style={{ cursor: 'pointer' }}>
          {isExpanded ? <FormattedMessage id="ShowLess"/> : <FormattedMessage id="ShowMore"/>}
        </span>
      )}<br></br>
        <b><FormattedMessage id="Price"/>:</b> {item.price} {item.culture.numberFormat.currencySymbol}<br></br>
        </Card.Text>
        <div style={{display:"flex"}}><a className="btn btn-dark-blue" data-doctorid={item.doctorId} onClick={() => GetCalendar(item.doctorId, item.price, 0, "0")}><FormattedMessage id="Book"/></a>{warning}</div>
      </Card.Body>
      </Card>;
  }

function getProgress(item){
  if(item>70){
   return <ProgressBar variant="success" now={item} style={{marginTop:"4px", marginLeft:"10px", width:"60%"}}/>;
  }else if(item>40){
    return <ProgressBar variant="info" now={item} style={{marginTop:"4px", marginLeft:"10px", width:"60%"}}/>;
  }
  else if(item>20){
    return <ProgressBar variant="warning" now={item} style={{marginTop:"4px", marginLeft:"10px", width:"60%"}}/>;
  }else{
    return <ProgressBar variant="danger" now={item} style={{marginTop:"4px", marginLeft:"10px", width:"60%"}}/>;
  }
}

function GetClass(){
  const isLoggedIn = localStorage.getItem('access_token');
  if (isLoggedIn === null)
  {
    return "btn btn-dark-blue row-45 disabled";
  }else{
    return "btn btn-dark-blue row-45";
  }
}

const resetSearch = () => {
  document.getElementById("CardLocation").style.display="none";
  document.getElementById("DoctorDiv").style.display="none";
  setSelectedSpeciality([]);
  setSelectedDiseases([]);
  setNoStatementsAvailable(false);
  setSelectedSymptoms([]);
  searchModel.symptomIds=[];
  searchModel.diseaseIds=[];
  searchModel.bodyPartIds=[];
  searchModel.specialityIds=[];
  setCalendar([]);
  GetSpecialityList();
  GetSearchedItemDataLinq(false);
};

const resetSearchStatements = () => {
  document.getElementById("CardLocation").style.display="none";
  document.getElementById("DoctorDiv").style.display="none";
  setSelectedSpeciality([]);
  setSelectedDiseases([]);
  setNoStatementsAvailable(false);
  setSelectedSymptoms([]);
  searchModel.symptomIds=[];
  searchModel.diseaseIds=[];
  searchModel.bodyPartIds=[];
  searchModel.specialityIds=[];
  setCalendar([]);
};

function GetFrontPage(){
  if (isLoggedIn !== null) { 
        return <TargetText name= {location.pathname}/>;
    }else{
        return   <div id="FrontPage">
        <FrontPage />
        </div>;
    }
} 

const handleSearchOption = (e) => {
    var selectedOption = e.value;
    setSelectedSearchTypeOption(e);
    switch(selectedOption) {
      case 0:
          searchForBookings();
          break;
      case 1:
          searchForStatements();
          break;
      case 2:
          searchForHouseCalls();
          break;
      default:
        searchForBookings();
          break;
  }
}

function GetSearchTypeTile(){
  if (isMobile) { 
        return professionGroupIsSelected && (<div className="coverD" id="searchTypeDiv" style={{display:"none"}}>
          <div className="searchCard">
         <h4><FormattedMessage id="searchOptionsTitle"/></h4>
          <Form.Group>
            <Select className="row-Select medium-font-size" id="searchOptions" options={searchTypeOptions}  value={selectedSearchTypeOption} onChange={handleSearchOption}/>
        </Form.Group>
      </div>
   </div>);
    }else{
        return  professionGroupIsSelected && (<div className="coverD" id="searchTypeDiv"  style={{display:"none"}}>
          <h4><FormattedMessage id="searchOptionsTitle"/></h4>
              <div className="flexDiv">
                  <div style={{width:"29%"}}>
                    <a style={{width:"100%"}} id="searchForBookings" className="btn btn-dark-blue unselected-search-type" onClick={() => searchForBookings()}><FormattedMessage id="NewBooking"/></a>
                  </div>
                  <div style={{width:"29%"}}>
                    <a style={{width:"100%"}} id="searchForStatements" className="btn btn-dark-blue unselected-search-type" onClick={() => searchForStatements()}><FormattedMessage id="Statements"/></a>
                  </div>
                  <div style={{width:"29%"}}>
                    <a style={{width:"100%"}} id="searchForHouseCalls" className="btn btn-dark-blue unselected-search-type" onClick={() => searchForHouseCalls()}><FormattedMessage id="HouseCall"/></a>
                  </div>
              </div>
         </div>);
    }
} 

function GetProfessionGroupTile(){
  if (isMobile) { 
        return  <Form.Group>
        <Select className="row-Select medium-font-size" id="ProfessionGroupId" options={professions}  value={selectedProfessions} onChange={searchProfessionGroupDropdown}/>
        </Form.Group>;
    }else{
      let width = "29%";
      if(professions.length === 1){
        width = "96%";
      }else if(professions.length === 2){
        width = "45%";
      }
        return <div className="flexDiv">
                   {professions.map(item =>  
                    <div style={{width: width}}>
                      <a style={{width:"100%"}}  className="btn btn-dark-blue profession-group-button unselected-search-type" data-id={item.value} onClick={() => searchProfessionGroupButton(item.value)}>{item.label}</a>
                    </div>
                ) }
              </div>;
    }
} 

function SetSearchedControlsEnable() {
  document.getElementById("DiseaseIds")?.classList.remove('disabledDropdown');
  document.getElementById("SymptomsIds")?.classList.remove('disabledDropdown');
  document.getElementById("SpecialityIds")?.classList.remove('disabledDropdown');
}
function SetSearchedControlsDisable() {
  document.getElementById("DiseaseIds")?.classList.add('disabledDropdown');
  document.getElementById("SymptomsIds")?.classList.add('disabledDropdown');
  document.getElementById("SpecialityIds")?.classList.add('disabledDropdown');
}

function searchForStatements(){
  document.getElementById("bookingTile").style.display = "";
  if(oneProfession){
    if (isLoggedIn === null) { 
      document.getElementById("FrontPage").style.display="none";
    }else{
      document.getElementById("targetTextClass").style.display="none";
    }
    document.getElementById("breadcrumb").style.display = "";
   }
  if(document.getElementById("searchTypeDiv")){
    document.getElementById("searchTypeDiv").style.display = "none";
  }
    setStatement(true);
    if(!isMobile){
      document.getElementById("searchForStatements").classList.remove('unselected-search-type');
      document.getElementById("searchForHouseCalls").classList.add('unselected-search-type');
      document.getElementById("searchForBookings").classList.add('unselected-search-type');
    }
    setHouseCall(false);
    setBookingTitle("Statements");
    setSearchTypeMenu(intl.formatMessage({id: 'Statements'}));
    setSearchBodyText("SpecialityStatementBodytext");
    setStatementDoctors([]);
    setStatements([]);
    goToCard('CardSpeciality');
    resetSearchStatements();
    GetSpecialityListStatements();
    setTimeout(() => {
     document.getElementById("NextButton2").classList.add('disabled');
      }, 300);
}

function searchForBookings(){
  document.getElementById("bookingTile").style.display = "";
 if(oneProfession){
  if (isLoggedIn === null) { 
    document.getElementById("FrontPage").style.display="none";
  }else{
    document.getElementById("targetTextClass").style.display="none";
  }
  document.getElementById("breadcrumb").style.display = "";
 }
  if(document.getElementById("searchTypeDiv")){
    document.getElementById("searchTypeDiv").style.display = "none";
  }
  if(!isMobile){
    document.getElementById("searchForStatements").classList.add('unselected-search-type');
    document.getElementById("searchForHouseCalls").classList.add('unselected-search-type');
    document.getElementById("searchForBookings").classList.remove('unselected-search-type');
  }
    setBookingTitle("NewBooking");
    setSearchTypeMenu(intl.formatMessage({id: 'NewBooking'}));
    setSearchBodyText("SpecialityBodytext");
    setStatement(false);
    setHouseCall(false);
    setStatementDoctors([]);
    setStatements([]);
    resetDoctors();
    document.getElementById("NextButton2").classList.remove('disabled');
}

function searchForHouseCalls(){
  document.getElementById("bookingTile").style.display = "";
  if(oneProfession){
      if (isLoggedIn === null) { 
        document.getElementById("FrontPage").style.display="none";
      }else{
        document.getElementById("targetTextClass").style.display="none";
      }
      document.getElementById("breadcrumb").style.display = "";
   }
  if(document.getElementById("searchTypeDiv")){
    document.getElementById("searchTypeDiv").style.display = "none";
  }
  if(!isMobile){
    document.getElementById("searchForStatements").classList.add('unselected-search-type');
    document.getElementById("searchForHouseCalls").classList.remove('unselected-search-type');
    document.getElementById("searchForBookings").classList.add('unselected-search-type');
  }
    setHouseCall(true);
    setStatement(false);
    setBookingTitle("HouseCall");
    setSearchTypeMenu(intl.formatMessage({id: 'HouseCall'}));
    setStatementDoctors([]);
    setStatements([]);
    GetServiceForHouseCall();
    goToCard('CardSpeciality');
    document.getElementById("CardLocation").style.display="none";
    document.getElementById("DoctorDiv").style.display="none";
    setSelectedSpeciality([]);
    setSelectedDiseases([]);
    setNoStatementsAvailable(false);
    setSelectedSymptoms([]);
    searchModel.symptomIds=[];
    searchModel.diseaseIds=[];
    searchModel.bodyPartIds=[];
    searchModel.specialityIds=[];
    setCalendar([]);
}

function searchForProfessionGroups(){
    setHouseCall(false);
    setStatement(false);
    setStatementDoctors([]);
    setStatements([]);
    GetServiceForProfessionGroup();
    goToCard('CardSpeciality');
    document.getElementById("CardLocation").style.display="none";
    document.getElementById("DoctorDiv").style.display="none";
    setSelectedSpeciality([]);
    setSelectedDiseases([]);
    setNoStatementsAvailable(false);
    setSelectedSymptoms([]);
    searchModel.symptomIds=[];
    searchModel.diseaseIds=[];
    searchModel.bodyPartIds=[];
    searchModel.specialityIds=[];
    setCalendar([]);
}

function GetServiceForProfessionGroup(){
  SetSearchedControlsDisable();
  document.getElementById("LoadingDiv").style.display="flex";
  document.getElementById("HeightDiv").style.display="none";
  setCalendar([]);
  document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'Loading'});
  searchModel.location.currentAddress=document.getElementById('AddressId').value;
  searchModel.maxDistance=document.getElementById("MaxDistance").value;
  fetch(config.url.BASE_URL+'Home/GetServiceForProfessionGroup',{
    method:'POST',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    },
    body:JSON.stringify(searchModel)
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          setActive(false);
          setSelectedStatement([]);
          setStatementDoctors([]);
          setStatements(result.selectModels);
          if(result.services.length === 0 && searchModel.specialityIds.length !== 0){
            setNoStatementsAvailable(true);
          }else{
            setNoStatementsAvailable(false);
          }
          setStatementsList(result.services);
          if(result.selectModels.length===1){
            searchStatement(result.selectModels[0]);
          }else{
            document.getElementById("LoadingDiv").style.display="none";
          }
          SetSearchedControlsEnable();
    }
    )
}

function GetServiceForHouseCall(){
  SetSearchedControlsDisable();
  document.getElementById("LoadingDiv").style.display="flex";
  document.getElementById("HeightDiv").style.display="none";
  setCalendar([]);
  document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'Loading'});
  searchModel.location.currentAddress=document.getElementById('AddressId').value;
  searchModel.maxDistance=document.getElementById("MaxDistance").value;
  searchModel.houseCall = true;
  fetch(config.url.BASE_URL+'Home/GetServiceForHouseCall',{
    method:'POST',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    },
    body:JSON.stringify(searchModel)
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          setActive(false);
          setSelectedStatement([]);
          setStatementDoctors([]);
          setStatements(result.selectModels);
          if(result.services.length === 0 && searchModel.specialityIds.length !== 0){
            setNoStatementsAvailable(true);
          }else{
            setNoStatementsAvailable(false);
          }
          setStatementsList(result.services);
          if(result.selectModels.length===1){
            searchStatement(result.selectModels[0]);
          }else{
            document.getElementById("LoadingDiv").style.display="none";
          }
          SetSearchedControlsEnable();
    }
    )
}

function GetStatementsForSpeciality(){
  SetSearchedControlsDisable();
  document.getElementById("LoadingDiv").style.display="flex";
  document.getElementById("HeightDiv").style.display="none";
  setCalendar([]);
  document.getElementById("LoadingSearch").innerHTML=intl.formatMessage({id: 'Loading'});
  searchModel.location.currentAddress=document.getElementById('AddressId').value;
  searchModel.maxDistance=document.getElementById("MaxDistance").value;
  searchModel.houseCall = false;
  searchModel.culture = localStorage.getItem('lang');
  fetch(config.url.BASE_URL+'Home/GetStatementsForSpeciality',{
    method:'POST',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    },
    body:JSON.stringify(searchModel)
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          setActive(false);
          setSelectedStatement([]);
          setStatementDoctors([]);
          setStatements(result.selectModels);
          if(result.services.length === 0 && searchModel.specialityIds.length !== 0){
            setNoStatementsAvailable(true);
          }else{
            setNoStatementsAvailable(false);
          }
          if(result.selectModels.length === 1){
            searchStatement(result.selectModels[0]); 
          }
          if(result.selectModels.length===1){
            searchStatement(result.selectModels[0]);
          }else{
            document.getElementById("LoadingDiv").style.display="none";
          }
          setStatementsList(result.services);
          SetSearchedControlsEnable();
    }
    )
  }

const searchStatement = (e) => {
  setSelectedStatement(e);
  getDoctorsForStatement(e.value);
}

function getDoctorsForStatement(serviceId){
  searchModel.serviceId=serviceId;
  document.getElementById("LoadingDiv").style.display="flex";
  searchModel.culture = localStorage.getItem('lang');
  fetch(config.url.BASE_URL+'Home/GetDoctorsForStatement',{
    method:'POST',
    headers:{
        'Accept':'application/json',
        'Content-Type':'application/json'
    },
    body:JSON.stringify(searchModel)
    })
    .then(res=>res.json())
    .then((result)=>{
          console.log(result);
          document.getElementById("LoadingDiv").style.display="none";
          document.getElementById("CardLocation").style.display="flex";
          setStatementDoctors(result);
    }
    )
}

function getDoctorStatementTile(item){
  var distance = item.distance;
  if(item.distance.toString().includes(".")){
    distance=item.distance.toString().substring(0,item.distance.toString().indexOf("."));
  }
    var firstTimeSlot = item.firstAvailableTime.substring(8, 10)+" "+getMonth(item.firstAvailableTime.substring(5,7));
    if(item.firstAvailableTime==="0001-01-01T00:00:00"){
      firstTimeSlot=<FormattedMessage id="No date"/>;
    }
      return <Card style={{color: "#007bff",marginBottom:"10px", width:"100%"}}>
      <Card.Header  as="h5" style={{background:"#e7f1ff"}}>{firstTimeSlot} - {item.name}</Card.Header>
      <Card.Body>
        <Card.Text>
        <b><FormattedMessage id="Speciality"/>:</b> {item.specialityName}<br></br>
        <div style={{display:"flex"}}><b><FormattedMessage id="Availability"/>:</b> {getProgress(item.availability)}<br></br></div>
        <b><FormattedMessage id="Distance"/>:</b> {distance} km<br></br>
        <b><FormattedMessage id="Services"/>:</b> {item.serviceName}<br></br>
        <b><FormattedMessage id="Price"/>:</b> {item.price} {item.culture.numberFormat.currencySymbol}<br></br>
        </Card.Text>
       {(statement || professionGroup) && <div style={{display:"flex"}}><a className="btn btn-dark-blue" data-doctorid={item.doctorId} onClick={() => GetCalendar(item.doctorId, item.price, item.duration, "0")}><FormattedMessage id="Book"/></a></div>}
       {houseCall && <div style={{display:"flex"}}><a className="btn btn-dark-blue" data-doctorid={item.doctorId} onClick={() => GetCalendar(item.doctorId, item.price, item.duration*2, distance)}><FormattedMessage id="Book"/></a></div>}
      </Card.Body>
      </Card>;
  }

 const goToFrontpage = () => {
  if (isLoggedIn === null) { 
    document.getElementById("FrontPage").style.display="";
  }else{
    document.getElementById("targetTextClass").style.display="";
  }
  document.getElementById("breadcrumb").style.display = "none";
  if(oneProfession){
      setSearchTypeMenu(null);
      goToSearchType();
      if(document.getElementById("searchForBookings")){
        document.getElementById("searchForBookings").classList.add("unselected-search-type");
      }
      if(document.getElementById("searchForHouseCalls")){
        document.getElementById("searchForHouseCalls").classList.add("unselected-search-type");
      }
      if(document.getElementById("searchForStatements")){
        document.getElementById("searchForStatements").classList.add("unselected-search-type");
      }
      setSelectedSearchTypeOption([]);
      if(document.getElementById("bookingTile")){
        document.getElementById("bookingTile").style.display = "none";
      }
      if(document.getElementById("DoctorDiv")){
        document.getElementById("DoctorDiv").style.display = "none";
      }
      setSelectedSpeciality([]);
      setSelectedDiseases([]);
      setSearchedDoctors([]);
      setNoStatementsAvailable(false);
      setSelectedSymptoms([]);
      searchModel.symptomIds=[];
      searchModel.diseaseIds=[];
      searchModel.bodyPartIds=[];
      searchModel.specialityIds=[];
      if(document.getElementById("CardLocation")){
        document.getElementById("CardLocation").style.display = "none";
      }
      setCalendar([]);
      setStatementDoctors([]);
    }else{
      if(professionGroupMenu){
        goToProfessionGroup();
        setProfessionGroupMenu(null);
      }
      setSelectedProfessions([]);
      let professionButtons = document.getElementsByClassName("profession-group-button");
      if(professionButtons){
        for (let i = 0; i < professionButtons.length; i++) {
          professionButtons[i].classList.add("unselected-search-type");
        }
      }
      if (isLoggedIn === null) { 
        document.getElementById("FrontPage").style.display="";
      }else{
        document.getElementById("targetTextClass").style.display="flex";
      }
    }
};


const goToSearchType = () => {
        setSearchedDoctors([]);
        setStatementDoctors([]);
        setCalendar([]);
        if(document.getElementById("CardLocation")){
          document.getElementById("CardLocation").style.display = "none";
        }
        if(document.getElementById("bookingTile")){
          document.getElementById("bookingTile").style.display = "none";
        }
        if(document.getElementById("DoctorDiv")){
          document.getElementById("DoctorDiv").style.display = "none";
        }
        if(document.getElementById("searchTypeDiv")){
          document.getElementById("searchTypeDiv").style.display = "";
        }
};

const goToProfessionGroup = () => {
    setSearchTypeMenu(null);
    if(searchTypeMenu){
      if(document.getElementById("searchForBookings")){
        document.getElementById("searchForBookings").classList.add("unselected-search-type");
      }
      if(document.getElementById("searchForHouseCalls")){
        document.getElementById("searchForHouseCalls").classList.add("unselected-search-type");
      }
      if(document.getElementById("searchForStatements")){
        document.getElementById("searchForStatements").classList.add("unselected-search-type");
      }
      setSelectedSearchTypeOption([]);
      if(document.getElementById("bookingTile")){
        document.getElementById("bookingTile").style.display = "none";
      }
      if(document.getElementById("DoctorDiv")){
        document.getElementById("DoctorDiv").style.display = "none";
      }
      setSelectedSpeciality([]);
      setSelectedDiseases([]);
      setSearchedDoctors([]);
      setNoStatementsAvailable(false);
      setSelectedSymptoms([]);
      searchModel.symptomIds=[];
      searchModel.diseaseIds=[];
      searchModel.bodyPartIds=[];
      searchModel.specialityIds=[];
  }
  setStatements([]);
  if(document.getElementById("CardLocation")){
    document.getElementById("CardLocation").style.display = "none";
  }
  if(document.getElementById("searchTypeDiv")){
    document.getElementById("searchTypeDiv").style.display = "none";
  }
  setCalendar([]);
  setStatementDoctors([]);
  document.getElementById("professionGroupDiv").style.display = "";
};



if (roles !==null && roles.includes(Roles.Doctor)) {
  window.location.href="/dashboard";
}else{
  return (
    <LoadingOverlay
        active={false}
        spinner
        style={{height:"122vh"}}
        text={intl.formatMessage({id: 'Loading'})}
        className="loader"
        >
    <div style={{height:"122vh"}}> 
         <NewNavBar />
        <div className="pageD container">
        {GetFrontPage()}
        <div className="cover-breadcrumb" id="breadcrumb" style={{display:"none"}}>
          <span><span style={{marginRight:"10px",bottom:"2px",position:"relative"}}><FaHome style={{color:"#0c4a7c"}}/></span>
                <span className="breadcrumb-item-h2t" onClick={goToFrontpage}><FormattedMessage id="GoToFrontpage"/></span>
                {professionGroupMenu && !oneProfession && (
                    <span>
                      <span> / </span>
                      <span className="breadcrumb-item-h2t" onClick={goToProfessionGroup}>{professionGroupMenu}</span>
                    </span>
                )}
                {searchTypeMenu && (
                    <span>
                    <span> / </span>
                    <span className="breadcrumb-item-h2t" onClick={goToSearchType}>{searchTypeMenu}</span>
                  </span>
                )}
                </span>
         </div>
        <div className="coverD" id="professionGroupDiv" style={{display:"none"}}>
             <div className='searchCard'>
                  <h4><FormattedMessage id="ProfessionGroups"/></h4>
                  <h6><FormattedMessage id="ProfessionGroupsText"/>:</h6>
                 {GetProfessionGroupTile()}
              </div>
         </div>
        {GetSearchTypeTile()}
           <div className="coverD" id="bookingTile" style={{display:"none"}}>
                <div style={{display:"flex",width:"100%"}}><div style={{width:"33.3%"}}></div><div style={{width:"33.3%",textAlign:"center"}}><h4><FormattedMessage id={bookingTitle}/></h4></div><div style={{width:"33.3%"}}></div></div>
                <Form className="search-form">


                       {(!houseCall && !professionGroup) && (<div id='CardSpeciality' className='searchCard'>
                            <h6><FormattedMessage id={searchBodyText}/></h6>
                            <div className="flexDiv" style={{marginBottom:"20px"}}>
                            <a className="btn btn-dark-blue row-45 disabled" onClick={resetSearch}><FaAngleLeft /></a>
                            <a className="btn btn-dark-blue row-45" id='NextButton2' onClick={() => goToCard('CardSymptomsFirst')}><FaAngleRight /></a>
                            </div>
                            <Form.Group>
                                <Select className="row-Select medium-font-size" id="SpecialityIds" options={specialities} value={selectedSpeciality} placeholder={<FormattedMessage id="SelectSpeciality"/>} isMulti onChange={searchSpeciality} />
                            </Form.Group>
                            {statementError && (<h6 style={{color:"red"}}><FormattedMessage id="SelectSpecialityToContinue"/></h6>)}
                      </div>)}


                      <div id='CardSymptoms' style={{display:"none"}} className='searchCard'>
                            <h6><FormattedMessage id="SymptomsBodytext"/></h6>
                            <div className="flexDiv" style={{marginBottom:"20px"}}>
                            <a className="btn btn-dark-blue row-45" onClick={() => goToCard('CardSpeciality')}><FaAngleLeft /></a>
                            <a className="btn btn-dark-blue row-45"  id='NextButton3' onClick={() => goToCard('CardDisease')}><FaAngleRight /></a>
                            </div>
                            <Form.Group >
                            <Select className="row-Select medium-font-size" id="SymptomsIds" options={symptoms} placeholder={<FormattedMessage id="SelectSymptom"/>} value={selectedSymptoms} isMulti onChange={searchSymptoms}/>
                            </Form.Group>
                      </div>


                      <div id='CardDisease' style={{display:"none"}} className='searchCard'>
                            <h6><FormattedMessage id="DiseaseBodytext1"/><b><FormattedMessage id="DiseaseBodytext2"/></b></h6>
                            <div className="flexDiv" style={{marginBottom:"20px"}}>
                            <a className="btn btn-dark-blue row-45" onClick={() => goToCard('CardSymptoms')}><FaAngleLeft /></a>
                            <a className="btn btn-dark-blue row-45" onClick={resetDoctors}><FaRedo /></a>
                            </div>
                            <Form.Group >
                            <Select className="row-Select medium-font-size" id="DiseaseIds" options={diseases} placeholder={<FormattedMessage id="SelectDisease"/>} value={selectedDiseases}  onChange={searchDisease}/>
                            </Form.Group>
                      </div>
                </Form>
            </div>

            {((statements.length !== 0 && statement) || (statements.length !== 0 && professionGroup) || (statements.length > 1 && houseCall)) && ( <div className="coverD"><div id='CardStatement' className='searchCard'>
                                  {statement && (<h6><FormattedMessage id="StatementBodytext"/></h6>)}
                                  {houseCall && (<h6><FormattedMessage id="HouseCallBodytext"/></h6>)}
                                  {professionGroup && (<h6><FormattedMessage id="ProfessionGroupsBodytext"/></h6>)}
                                  <Form.Group>
                                      <Select className="row-Select medium-font-size" id="StatementIds" options={statements} value={selectedStatement} placeholder={<FormattedMessage id="SelectStatement"/>} onChange={searchStatement} />
                                  </Form.Group>
                                  </div></div>)}

            {noStatementsAvailable && ( <div className="coverD"><div className='searchCard'>
                                  <h6><FormattedMessage id="NoAvailavbeTimeSlots"/></h6>
                                  </div></div>)}

            <div id='CardLocation' className='searchCard coverD' style={{display:"none"}}>
                            <h4><FormattedMessage id="LocationBodytext"/></h4>
                              <div className="flexDiv">
                                <h6 className="row-45"><FormattedMessage id="Maxdistanceinkm"/></h6>
                                                <Form.Select className="row-Search row-45" id='MaxDistance' onChange={() => GetMaxDistance()} style={{width:"42%"}}>
                                                  <option value="25"><FormattedMessage id="25km"/></option>
                                                  <option value="50"><FormattedMessage id="50km"/></option>
                                                  <option value="100"><FormattedMessage id="100km"/></option>
                                                  <option value="200" selected><FormattedMessage id="200km"/></option>
                                                  <option value="500"><FormattedMessage id="500km"/></option>
                                              </Form.Select>
                                </div>
                            <Form.Group style={{width:"100%"}}>
                                 <AutoComplete />
                            </Form.Group>
                            <div className="flexDiv" style={{marginBottom:"20px"}}>
                              <a className="btn btn-dark-blue row-45" onClick={GetCurrentLocation}><FaCrosshairs /></a>
                              <a className={GetClass()} id='HomeLocation' onClick={GetHomeLocation}> <FaHome /></a>
                            </div>
                      </div>
            

            <div id='LoadingDiv' className="LoadingDiv">
              <Rings
              height="100"
              width="100"
              radius="9"
              color="#0c4a7c"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
            <h6 id='LoadingSearch'><FormattedMessage id="CalculateDistance"/></h6>
           </div>
              <div className="coverD" id='WarningDoctorDiv' style={{display:"none"}}>
            <h6 style={{color:"red"}}><FormattedMessage id="WarningDoctorText"/></h6>
            </div>
            
            <div className="coverD" id='DoctorDiv' style={{display:"none"}}>
            <h4><FormattedMessage id="Doctors"/></h4>
            <h6><FormattedMessage id="OrderBy"/></h6>
            <div className="flexDiv" style={{marginBottom:"20px",width:"100%"}}>
            <a className="btn btn-dark-blue" onClick={() => OrderBy('Distance')}><FormattedMessage id="Distance"/></a>
            <a className="btn btn-dark-blue" onClick={() => OrderBy('Price')}><FormattedMessage id="Price"/></a>
            <a className="btn btn-dark-blue" onClick={() => OrderBy('Availability')}><FormattedMessage id="FirstAvailableTime"/></a>
            </div>
            {searchedDoctors.map((item, index) => (getDistance(item, index)))}
            </div>

                       
            {statementDoctors.length !== 0 && (<div className="coverD" >
            <h4><FormattedMessage id="Doctors"/></h4>
            {statementDoctors.map(item => (getDoctorStatementTile(item)))}
            </div>)}


            {getDoctorCalendar()}
            <div id='LoadingDivCalendar' className="LoadingDiv">
              <Rings
              height="100"
              width="100"
              radius="9"
              color="#0c4a7c"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
            <h6><FormattedMessage id="Loading"/></h6>
            </div>
            <div id='HeightDiv' style={{height:"400px",display:"block"}}></div>
            <div id='LoadingDiv2'>
            </div>
            <div className="coverD">
            <div style={{textAlign:"-webkit-center"}}>
                        <Footer />
                        </div>
                        {active && ( <Loading />)}
            </div>
        </div>
    </div>
     </LoadingOverlay>
  )
}
}

export default SearchDashboard
import React, { useState } from "react";
import axios from "../../context/axios_instance";
import {FormattedMessage,useIntl} from 'react-intl';
import { useNavigate } from "react-router-dom";
import * as Functions from '../../functions/functions';
import {config} from '../../Constants';
import Popup from "../popups/popup";
import Loading from "../loading/loading";
import CloseButton from 'react-bootstrap/CloseButton';
var changeDoctor = true;
function DoctorCalendar(props) {
    var firstItem='';
    const navigate = useNavigate();
    let [content, setContent] = useState(null); 
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
      }
    const intl = useIntl();
    const [active, setActive] = useState(false);
    const [statement, setStatement] = useState(false);
    const [houseCall, setHouseCall] = useState(false);
    const [professionGroup, setProfessionGroup] = useState(false);
    const [infoB, setInfo] = useState([]);
    let [timeslots, setTimeslots] = useState([]);
    let [weekStart, setWeekStart] = useState(null);
    let [doctorId, setDoctorId] = useState(null);
    if(doctorId !== props.data.doctor.doctorId){
        setTimeslots(props.data.timeSlots);
        setStatement(props.statement);
        setHouseCall(props.houseCall);
        setProfessionGroup(props.professionGroup);
        setWeekStart(props.data.weekstart.substring(0,10));
        setDoctorId(props.data.doctor.doctorId);
    }
    if(document.getElementById("CalendarDiv") !== null){
        if(active !== true){
            document.getElementById("CalendarDiv").classList.remove("d-none");
        }
    }
    const [TS, setTS] = useState([]);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    function DoctorBookigInfo(info,diseaseId,searchedServices,item,date,ts){
        document.getElementById("HeightDiv").style.display="block";
        document.getElementById("LoadingDiv2").style.display="flex";
        document.getElementById("LoadingDiv2").scrollIntoView();
        setInfo(info);
        changeDoctor = false;
        let Ts = {
            TSid:item.timeSlotId,
            bookingDate:date,
            diseaseId:diseaseId,
            doctorFees:item.doctorFee,
            searchedServices:searchedServices,
            bookingTS:ts,
            item:item
        }
        setTS(Ts);
    }

    function removeTile(id){
        document.getElementById(id).classList.add("d-none");
        if(id==="CalendarDiv" && document.getElementById("DoctorBookingInfoDiv") !== null){
            document.getElementById("DoctorBookingInfoDiv").classList.add("d-none");
        }
    }

    function getDoctorBookingInfo(){
        if(infoB.length !== 0 && changeDoctor !== true){
            changeDoctor = true;
            if(document.getElementById("DoctorBookingInfoDiv") !== null){
                document.getElementById("DoctorBookingInfoDiv").classList.remove("d-none");
            }
            document.getElementById("HeightDiv").style.display="none";
            document.getElementById("LoadingDiv2").style.display="none";
            var doctorFeeArray=[];
            var doctorFeePayerArray=[];
            var totalCC=props.doctorPrice;
            var totalIC=props.doctorPrice;
            TS.doctorFees?.forEach(element => {
                if(element.type != '0'){
                    doctorFeeArray.push(`${element.name}: ${element.price} kr.`);
                    totalCC+=element.price;
                    totalIC+=element.price;
                }else{
                    if(element.specification.includes('0')){
                        doctorFeePayerArray.push(`${intl.formatMessage({id: 'CreditCard'})} - ${element.name}: ${element.price} kr.`);
                        totalCC+=element.price;
                    }if(element.specification.includes('2')){
                        doctorFeePayerArray.push(`${intl.formatMessage({id: 'InsuranceCompany'})} - ${element.name}: ${element.price} kr.`);
                        totalIC+=element.price;
                    }
                }
            });
            var doctorFeeDiv="";
            var doctorFeePayerDiv="";
            if(doctorFeeArray.length !=0){
                    doctorFeeDiv=<div>
                    <b><FormattedMessage id="Doctor Fee"/></b>
                    <p>{doctorFeeArray.join(', ')}</p></div>;
            }
            if(doctorFeePayerArray.length !=0){
                doctorFeePayerDiv=<div>
                 <b><FormattedMessage id="Doctor fee depending on the payment method"/></b>
                 <p>{doctorFeePayerArray.join(', ')}</p></div>;
            }
            TS.houseCallDuration="0";
            if(statement || houseCall){
                var d1=new Date(TS.item.start);
                var d2=new Date(d1);
                d2.setMinutes(d1.getMinutes()+TS.item.duration+props.differenceDuration);
                var date30=d2.toString().substring(16,21);
                var ts=TS.item.start.substring(11,16) +" - "+ date30;
                TS.bookingTS = ts;
                TS.houseCallDuration = TS.item.duration+props.differenceDuration;
            }

            return <div className="coverD" id="DoctorBookingInfoDiv">
                 <div style={{width:"100%",marginBottom:"10px"}}><CloseButton onClick={() => removeTile("DoctorBookingInfoDiv")} style={{float:"right", backgroundColor:"black"}}/></div>
                    <h4>{infoB.doctor.title} {infoB.doctor.fullName}</h4>
                    <h5>{infoB.doctor.speciality.name}</h5>
                    <div>
                        <b><FormattedMessage id="Bookingdate"/></b>
                        <p>{TS.bookingDate} {TS.bookingTS}</p>
                        <b><FormattedMessage id="Address"/></b>
                        <p>{infoB.doctor.address.googleAddress}</p>
                        <b><FormattedMessage id="PhoneNumber"/></b>
                        <p>{infoB.doctor.cellPhoneNumber}</p>
                        <b><FormattedMessage id="Price"/></b>
                        <p><FormattedMessage id="ServicesTotal"/>: {props.doctorPrice}  kr.</p>
                        {doctorFeeDiv}
                        {doctorFeePayerDiv}
                    </div>
                    <div className="flexDiv" style={{marginBottom:"5px"}}>
                            <a className="btn btn-dark-blue row-45" id="creditcardBtn" onClick={() => BookingConfirmationCC(TS)}><FormattedMessage id="CC"/></a>
                            <a className="btn btn-dark-blue row-45" id="insuranceBtn" onClick={() => BookingConfirmationIC(TS)}><FormattedMessage id="IC"/></a>
                        </div>
                        <div className="flexDiv">
                            <b><FormattedMessage id="Total"/>: {totalCC} kr.</b>
                            <b><FormattedMessage id="Total"/>: {totalIC} kr.</b>
                        </div>
                    </div>
                    
          }
    }
    function BookingConfirmationCC(TS){
        const isLoggedIn = localStorage.getItem('access_token');
        changeDoctor = false;
        setActive(true);
        document.getElementById("LoadingDiv2").style.display="flex";
        document.getElementById("LoadingDiv2").scrollIntoView();
        let serviceIds=[];
        TS.searchedServices.forEach(item => {
            if(item.preSelected===true){
                serviceIds.push(item.serviceId)
            }
            
        });
        let model = {
            timeSlotId:TS.TSid,
            diseaseId:TS.diseaseId,
            serviceIds:serviceIds,
            statement:statement,
            isProfessionGroupSearch:professionGroup,
            houseCallDuration:TS.houseCallDuration.toString(),
            houseCallDistance:props.doctorDrivingDistance,
            houseCall:houseCall,
            paymentMode:1,
            language:localStorage.getItem('lang')
        }
        if (isLoggedIn === null)
        {
            model.address=props.address;
            navigate("/login", { state: { props: model, patientAddress: props.patientAddress } })
        }else{
            axios.post(`/Patient/Home/BookingConfirmation`,model)
            .then(
              (result) => {
                navigate("/patient/bookingconfirmation", { state: { props: result.data , patientAddress: props.patientAddress } })
              }
            )
        }
    }
    function BookingConfirmationIC(TS){
        const isLoggedIn = localStorage.getItem('access_token');
        changeDoctor = false;
        setActive(true);
        document.getElementById("LoadingDiv2").style.display="flex";
        document.getElementById("LoadingDiv2").scrollIntoView();
        let serviceIds=[];
        TS.searchedServices.forEach(item => {
            if(item.preSelected===true){
                serviceIds.push(item.serviceId)
            }
            
        });
        let model = {
            timeSlotId:TS.TSid,
            diseaseId:TS.diseaseId,
            serviceIds:serviceIds,
            statement:statement,
            isProfessionGroupSearch:professionGroup,
            houseCallDuration:TS.houseCallDuration.toString(),
            houseCallDistance:props.doctorDrivingDistance,
            houseCall:houseCall,
            paymentMode:2,
            language:localStorage.getItem('lang')
        }
        if (isLoggedIn === null)
        { 
            model.address=props.address;
            navigate("/login", { state: { props: model, patientAddress: props.patientAddress } })
       
        }else{
            axios.post(`/Patient/Home/BookingConfirmation`,model)
            .then(
              (result) => {
                navigate("/patient/bookingconfirmation", { state: { props: result.data, patientAddress: props.patientAddress } })
              }
            )
        }
    }
    function getDoctorCalendarMessage(){
        if(props.diseaseId==='00000000-0000-0000-0000-000000000000' && props.symptomIds.length === 0){
            return  <h6 id="DoctorCalendarMessage" style={{color:"red"}}><FormattedMessage id="DoctorCalendarMessage"/></h6>;
        }else if(timeslots.length===0){
            return <h6><FormattedMessage id="NoAvailavbeTimeSlotsForDoctor"/></h6>;
        }
    }
    function getCalendar(item){
        var d1=new Date(item.start);
        var d2=new Date(d1);
        if(houseCall){
            d2.setMinutes(d1.getMinutes()+item.duration+props.differenceDuration-props.doctorDrivingDuration);
        }else {
            d2.setMinutes(d1.getMinutes()+item.duration+props.differenceDuration);
        }
        var date30=d2.toString().substring(16,21);
        let date  = new Date(item.start);
        let language = localStorage.getItem('lang');
        var ts=item.start.substring(11,16) +" - "+ date30;
        if(statement || houseCall || professionGroup){
            if(item.start.substring(0,10) !==firstItem){
                firstItem=item.start.substring(0,10);
                    return <div style={{width:"100%"}}>
                            <div style={{textAlign:"center",marginBottom:"10px"}}><b style={{color:"#0c4a7c",fontSize:"18px"}}>{date.toLocaleDateString(language, options)}</b></div>
                            <div style={{width:"100%",display:"flex"}}>
                            <div style={{marginLeft:"10px",marginTop:"16px",width:"25%",textAlign:"center"}}>{item.start.substring(11,16)} - {date30}</div>
                            <div style={{marginLeft:"10px",padding:"12px",width:"25%",textAlign:"center"}}>{getDoctorFee(item.doctorFee)}</div>
                            <div style={{margin:"10px",width:"50%"}}><div style={{width:"100%"}}  data-starttime={item.start}  onClick={() => DoctorBookigInfo(props.data,props.diseaseId,props.searchedServices,item,date.toLocaleDateString(language, options),ts)} className="btn btn-dark-blue"><FormattedMessage id="Select"/></div></div>
                        </div>
                        </div>;
            }else{
                return  <div style={{width:"100%",display:"flex"}}>
                        <div style={{marginLeft:"10px",marginTop:"16px",width:"25%",textAlign:"center"}}>{item.start.substring(11,16)} - {date30}</div>
                        <div style={{marginLeft:"10px",padding:"12px",width:"25%",textAlign:"center"}}>{getDoctorFee(item.doctorFee)}</div>
                        <div style={{margin:"10px",width:"50%"}}><div style={{width:"100%"}}  data-starttime={item.start}  onClick={() => DoctorBookigInfo(props.data,props.diseaseId,props.searchedServices,item,date.toLocaleDateString(language, options),ts)} className="btn btn-dark-blue"><FormattedMessage id="Select"/></div></div>
                    </div>;
            }
        }else{
            if(item.start.substring(0,10) !==firstItem){
                firstItem=item.start.substring(0,10);
                if(props.diseaseId===undefined && props.symptomIds.length === 0){
                    return <div style={{width:"100%"}}>
                        <div style={{textAlign:"center",marginBottom:"10px"}}><b style={{color:"#0c4a7c",fontSize:"18px"}}>{date.toLocaleDateString(language, options)}</b></div>
                        <div style={{width:"100%",display:"flex"}}>
                        <div style={{marginLeft:"10px",marginTop:"16px",width:"25%",textAlign:"center"}}>{item.start.substring(11,16)} - {date30}</div>
                        <div style={{marginLeft:"10px",padding:"16px",width:"25%",textAlign:"center"}}>{getDoctorFee(item.doctorFee)}</div>
                        <b style={{color:"#0c4a7c",fontSize:"16px",marginTop:"15px"}}><FormattedMessage id="Available"/></b>
                    </div>
                    </div>;
                }
                else{
                    return <div style={{width:"100%"}}>
                            <div style={{textAlign:"center",marginBottom:"10px"}}><b style={{color:"#0c4a7c",fontSize:"18px"}}>{date.toLocaleDateString(language, options)}</b></div>
                            <div style={{width:"100%",display:"flex"}}>
                            <div style={{marginLeft:"10px",marginTop:"16px",width:"25%",textAlign:"center"}}>{item.start.substring(11,16)} - {date30}</div>
                            <div style={{marginLeft:"10px",padding:"12px",width:"25%",textAlign:"center"}}>{getDoctorFee(item.doctorFee)}</div>
                            <div style={{margin:"10px",width:"50%"}}><div style={{width:"100%"}}  data-starttime={item.start}  onClick={() => DoctorBookigInfo(props.data,props.diseaseId,props.searchedServices,item,date.toLocaleDateString(language, options),ts)} className="btn btn-dark-blue"><FormattedMessage id="Select"/></div></div>
                        </div>
                        </div>;
                }
            }else{
                if(props.diseaseId===undefined && props.symptomIds.length === 0){
                        return  <div style={{width:"100%",display:"flex"}}>
                                <div style={{marginLeft:"10px",marginTop:"16px",width:"25%",textAlign:"center"}}>{item.start.substring(11,16)} - {date30}</div>
                                <div style={{marginLeft:"10px",padding:"16px",width:"25%",textAlign:"center"}}>{getDoctorFee(item.doctorFee)}</div>
                                <b style={{color:"#0c4a7c",fontSize:"16px",marginTop:"15px"}}><FormattedMessage id="Available"/></b>
                            </div>;
                }
                else{
                    return  <div style={{width:"100%",display:"flex"}}>
                            <div style={{marginLeft:"10px",marginTop:"16px",width:"25%",textAlign:"center"}}>{item.start.substring(11,16)} - {date30}</div>
                            <div style={{marginLeft:"10px",padding:"12px",width:"25%",textAlign:"center"}}>{getDoctorFee(item.doctorFee)}</div>
                            <div style={{margin:"10px",width:"50%"}}><div style={{width:"100%"}}  data-starttime={item.start}  onClick={() => DoctorBookigInfo(props.data,props.diseaseId,props.searchedServices,item,date.toLocaleDateString(language, options),ts)} className="btn btn-dark-blue"><FormattedMessage id="Select"/></div></div>
                        </div>;
                }    
            }
        }
    }

    function getDoctorName(){
        return  <h6>{props.data.doctor.title} {props.data.doctor.fullName}</h6>;
}

    function getDoctorFee(doctorFees){
        let uiItems = [];
        var countFees = doctorFees?.filter(model => model.type !== 0).length;
        var totalPrice = 0;
        var totalFees = [];
        doctorFees?.forEach(element => {
            if(element.type !== 0 && countFees === 1){
                uiItems.push(
                    <div onClick={() => ShowFeeName(element)} style={{padding:"0px 10px",cursor:"pointer",borderRadius:"0.375rem",color:"white", backgroundColor:element.color}}>
                        + {element.price} kr.
                    </div> 
                 )
            }else if(element.type !== 0 && countFees > 1){
                 totalPrice += element.price;
                 totalFees.push(element);
            }
        });
        if(countFees > 1){
            uiItems.push(
                <div onClick={() => ShowTotalFeeName(totalFees)} style={{padding:"0px 10px",cursor:"pointer",borderRadius:"0.375rem",color:"white", backgroundColor:"gray"}}>
                    + {totalPrice} kr.
                </div> 
             )
        }
        return <div style={{display:"flex"}}>{uiItems}</div>;
    }
    function ShowFeeName(element){
        var description="";
        if(element.type === 1){
             description= intl.formatMessage({id: 'DoctorFeeHours'})+": "+ element.specification;
        }else if(element.type === 2){
            var splitedDays=element.specification.split(",");
            var splitedDaysName=[];
            splitedDays.forEach(element => {
                splitedDaysName.push(intl.formatMessage({id: element.trim()}));
            });
            description= intl.formatMessage({id: 'DoctorFeeDays'})+": "+ splitedDaysName.join(", ");
        }
       var content = <div>
                    <div className="modal-header bg-dark-blue">
                    </div>
                    <div className="modal-body text-center">
                        <b style={{color: "#0c4a7c"}}>
                        {element.name}
                        </b><br></br>
                        <b style={{color: "#0c4a7c"}}>
                        {description}
                        </b>
                    </div>
                </div>;
        setContent(content);
        setIsOpen(!isOpen);
    }

    function ShowTotalFeeName(fees){
        let uiItems = [];
        fees.forEach(element => {
            var description="";
            if(element.type === 1){
                description= intl.formatMessage({id: 'DoctorFeeHours'})+": "+ element.specification;
           }else if(element.type === 2){
               var splitedDays=element.specification.split(",");
               var splitedDaysName=[];
               splitedDays.forEach(element => {
                   splitedDaysName.push(intl.formatMessage({id: element.trim()}));
               });
               description= intl.formatMessage({id: 'DoctorFeeDays'})+": "+ splitedDaysName.join(", ");
           }
           uiItems.push(
            <div style={{marginBottom:"15px"}}>
            <b style={{color: "#0c4a7c"}}>
            <span class="dot" style={{backgroundColor:element.color}}></span>{element.name}
            </b><br></br>
            <b style={{color: "#0c4a7c"}}>
            <FormattedMessage id="Price"/>: {element.price} kr.
            </b><br></br>
            <b style={{color: "#0c4a7c"}}>
            {description}
            </b>
        </div>
         )
        });
        var content = <div>
                        <div className="modal-header bg-dark-blue">
                        </div>
                        <div className="modal-body text-center">
                        {uiItems}
                        </div>
                    </div>;
        setContent(content);
        setIsOpen(!isOpen);
    }

    function GetCalendarForNextWeek(date,doctorId){
        if(document.getElementById("DoctorBookingInfoDiv") !== null){
            document.getElementById("DoctorBookingInfoDiv").classList.add("d-none");
        }
        var next= Functions.addDays(date,7);
        var lang = localStorage.getItem('lang');
        if(!lang){
          lang="da";
        }
        fetch(config.url.BASE_URL+`Home/GetDoctor/${doctorId}/${lang}/${props.doctorDrivingDistance}/${next.toISOString()}`)
        .then(response => response.json())
        .then(data => {
                if(statement || houseCall){
                    const filteredModels = [];
                    for (let i = 0; i < data.timeSlots.length; i++) {
                        let sum = 0;
                        for (let j = i + 1; j < data.timeSlots.length; j++) {
                            sum = timeDifference(data.timeSlots[i].start, data.timeSlots[j].start, j-i);
                            if(sum >= props.differenceDuration * 60 * 1000){
                            var exist = filteredModels.find(model => model.timeSlotId === data.timeSlots[i].timeSlotId);
                            if(!exist){
                                filteredModels.push(data.timeSlots[i]);
                            }
                            }
                        }
                    }
                    setTimeslots(filteredModels);
                }else{
                    setTimeslots(data.timeSlots);
                }
              setWeekStart(data.weekstart.substring(0,10));
              document.getElementById("CalendarDiv").scrollIntoView();
            } 
            );
    }

    function timeDifference(start, end, dif) {
        const startTime = new Date(start);
        const endTime = new Date(end);
        const differenceInMs = endTime.getTime() - startTime.getTime();
        if(30 * dif * 60 * 1000 !== differenceInMs){
          return 0;
        }
        return differenceInMs;
      }

    function GetCalendarForPreviousWeek(date,doctorId){
        if(document.getElementById("DoctorBookingInfoDiv") !== null){
            document.getElementById("DoctorBookingInfoDiv").classList.add("d-none");
        }
        var next= Functions.addDays(date,-7);

        var lang = localStorage.getItem('lang');
        if(!lang){
            lang="da";
        }
        fetch(config.url.BASE_URL+`Home/GetDoctor/${doctorId}/${lang}/${props.doctorDrivingDistance}/${next.toISOString()}`)
        .then(response => response.json())
        .then(data => {
            if(statement || houseCall){
                const filteredModels = [];
                for (let i = 0; i < data.timeSlots.length; i++) {
                    let sum = 0;
                    for (let j = i + 1; j < data.timeSlots.length; j++) {
                        sum = timeDifference(data.timeSlots[i].start, data.timeSlots[j].start, j-i);
                        if(sum >= props.differenceDuration * 60 * 1000){
                        var exist = filteredModels.find(model => model.timeSlotId === data.timeSlots[i].timeSlotId);
                        if(!exist){
                            filteredModels.push(data.timeSlots[i]);
                        }
                        }
                    }
                }
                setTimeslots(filteredModels);
            }else{
                setTimeslots(data.timeSlots);
            }
              setWeekStart(data.weekstart.substring(0,10));
              document.getElementById("CalendarDiv").scrollIntoView();
            } 
            );
    }
    function ShowPreviousButton(){
        if(new Date(weekStart) < new Date()){
            return "d-none";
        }else{
            return "btn btn-dark-blue ";
        }
    }
    return(
       <div>
            <div className="coverD" id="CalendarDiv">
            <div style={{width:"100%",marginBottom:"10px"}}><CloseButton onClick={() => removeTile("CalendarDiv")} style={{float:"right", backgroundColor:"black"}}/></div>
            <div style={{display:"flex",width:"100%"}}><div style={{width:"33.3%"}}><a id="PreviousBtn" onClick={() => GetCalendarForPreviousWeek(weekStart,doctorId)} className={ShowPreviousButton()}><FormattedMessage id="Previous"/></a></div><div style={{width:"33.3%",textAlign:"center"}}><h4><FormattedMessage id="Calendar"/></h4></div><div style={{width:"33.3%"}}><a style={{float:"right"}} className="btn btn-dark-blue" onClick={() => GetCalendarForNextWeek(weekStart,doctorId)}><FormattedMessage id="Next"/></a></div></div>
            {getDoctorName()}
            {getDoctorCalendarMessage()}
            {timeslots.map(item => (
            getCalendar(item)
            ))}
            </div>
            {getDoctorBookingInfo()}
            {isOpen && <Popup
            content={content}
            handleClose={togglePopup}
            />}
             {active && ( <Loading />)}
            </div>
        )
}
export default DoctorCalendar;